import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-green-400 mb-6">Privacy Policy</h1>
        <div className="space-y-4">
          <p>
            At Tekyu, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information.
          </p>
          {/* Add more sections as needed */}
          <h2 className="text-2xl font-semibold text-green-300 mt-8">1. Information We Collect</h2>
          <p>
            We collect information you provide directly to us, such as when you create an account or use our services.
          </p>
          <h2 className="text-2xl font-semibold text-green-300 mt-8">2. How We Use Your Information</h2>
          <p>
            We use the information we collect to provide, maintain, and improve our services, and to communicate with you.
          </p>
          {/* Continue with other sections */}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;