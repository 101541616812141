import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import tekyuLogo from './assets/tekyu-logo.png'; // Adjust this path if needed

const LandingPage = () => {
  const [willingToBeTechnical, setWillingToBeTechnical] = useState(null);
  const [wantsConfidentRole, setWantsConfidentRole] = useState(null);
  const [salaryRange, setSalaryRange] = useState('');
  const navigate = useNavigate();

  const roles = [
    { title: 'DevSecOps', path: '/devsecops', technical: true, confident: true, minSalary: 70000, highlightTechnical: true, highlightNonTechnical: true },
    { title: 'DevEx', path: '/devex', technical: true, confident: true, minSalary: 65000, highlightTechnical: true, highlightNonTechnical: false },
    { title: 'Cloud/Platform Engineer', path: '/cloud-platform-engineer', technical: true, confident: false, minSalary: 75000, highlightTechnical: true, highlightNonTechnical: false },
    { title: 'DevOps', path: '/devops-questionnaire', technical: true, confident: true, minSalary: 70000, highlightTechnical: true, highlightNonTechnical: false },
    { title: 'SRE', path: '/sre-questionnaire', technical: true, confident: false, minSalary: 80000, highlightTechnical: true, highlightNonTechnical: true },
    { title: 'Penetration Testing', path: '/penetration-testing', technical: true, confident: false, minSalary: 65000, highlightTechnical: true, highlightNonTechnical: false },
    { title: 'CyberGRC', path: '/cybergrc', technical: false, confident: true, minSalary: 60000, highlightTechnical: false, highlightNonTechnical: true },
    { title: 'Product Manager', path: '/product-manager', technical: false, confident: true, minSalary: 70000, highlightTechnical: false, highlightNonTechnical: true },
    { title: 'AI/Data Engineer', path: '/ai-data-engineer', technical: true, confident: false, minSalary: 80000, highlightTechnical: true, highlightNonTechnical: false },
    { title: 'Security Analyst', path: '/security-analyst', technical: true, confident: true, minSalary: 65000, highlightTechnical: true, highlightNonTechnical: true },
    { title: 'Full-Stack Engineer', path: '/full-stack-engineer', technical: true, confident: true, minSalary: 70000, highlightTechnical: true, highlightNonTechnical: false },
  ];

  const isRoleSuitable = (role) => {
    if (willingToBeTechnical === null && wantsConfidentRole === null && salaryRange === '') {
      return false;
    }

    // Special cases for DevSecOps and DevEx
    if ((role.title === 'DevSecOps' || role.title === 'DevEx') && 
        ((willingToBeTechnical === false && wantsConfidentRole === false) ||
         (willingToBeTechnical === true && wantsConfidentRole === false) ||
         (willingToBeTechnical === false && wantsConfidentRole === true))) {
      return true;
    }

    if (willingToBeTechnical !== null) {
      if (willingToBeTechnical && !role.highlightTechnical) return false;
      if (!willingToBeTechnical && !role.highlightNonTechnical) return false;
    }
    if (wantsConfidentRole !== null && role.confident !== wantsConfidentRole) return false;
    
    if (salaryRange === '46k-99k' && ['Cyber GRC', 'Security Analyst'].includes(role.title)) return false;
    if (salaryRange === '100k+' && !['DevSecOps', 'DevEx', 'Cloud/Platform Engineer', 'DevOps', 'SRE', 'AI/Data Engineer'].includes(role.title)) return false;
    return true;
  };

  const handleRoleClick = (path) => {
    if (path === '/devops-questionnaire' || path === '/sre-questionnaire' || 
        path === '/product-manager' || path === '/ai-data-engineer' || 
        path === '/full-stack-engineer') {
      navigate(path);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12">
        <img src={tekyuLogo} alt="Tekyu Logo" className="h-24 w-auto mx-auto mb-4" />
      </div>

      <div className="bg-gray-800 p-6 rounded-lg border border-green-400 mb-8 max-w-2xl w-full">
        <h3 className="text-2xl font-bold text-green-400 mb-4">Role Suitability</h3>
        <div className="space-y-4">
          <div>
            <p className="text-white mb-2">Are You Willing To Become More Technical?</p>
            <div className="flex space-x-4">
              <button 
                onClick={() => setWillingToBeTechnical(true)} 
                className={`px-4 py-2 rounded ${willingToBeTechnical === true ? 'bg-green-500 text-white' : 'bg-gray-600 text-gray-300'}`}
              >
                Yes
              </button>
              <button 
                onClick={() => setWillingToBeTechnical(false)} 
                className={`px-4 py-2 rounded ${willingToBeTechnical === false ? 'bg-green-500 text-white' : 'bg-gray-600 text-gray-300'}`}
              >
                No
              </button>
            </div>
          </div>
          <div>
            <p className="text-white mb-2">Do You Want A Role That Requires Confidence And Talking?</p>
            <div className="flex space-x-4">
              <button 
                onClick={() => setWantsConfidentRole(true)} 
                className={`px-4 py-2 rounded ${wantsConfidentRole === true ? 'bg-green-500 text-white' : 'bg-gray-600 text-gray-300'}`}
              >
                Yes
              </button>
              <button 
                onClick={() => setWantsConfidentRole(false)} 
                className={`px-4 py-2 rounded ${wantsConfidentRole === false ? 'bg-green-500 text-white' : 'bg-gray-600 text-gray-300'}`}
              >
                No
              </button>
            </div>
          </div>
          <div>
            <p className="text-white mb-2">How Much Do You Want To Earn Within 18 Months? (£)</p>
            <select
              value={salaryRange}
              onChange={(e) => setSalaryRange(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
            >
              <option value="">Select Salary Range</option>
              <option value="0-45k">£0 - £45,000</option>
              <option value="46k-99k">£46,000 - £99,000</option>
              <option value="100k+">£100,000+</option>
            </select>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl w-full">
        {roles.map((role, index) => (
          <div 
            key={index} 
            className={`p-6 rounded-lg border ${
              isRoleSuitable(role) 
                ? 'bg-green-800 border-green-400' 
                : 'bg-gray-800 border-green-400'
            }`}
          >
            {role.title === 'DevOps' || role.title === 'SRE' || role.title === 'Product Manager' || role.title === 'AI/Data Engineer' || role.title === 'Full-Stack Engineer' ? (
              <div 
                onClick={() => handleRoleClick(role.path)} 
                className="block text-center cursor-pointer"
              >
                <h3 className="text-2xl font-bold text-green-400 mb-2">{role.title}</h3>
                <p className="text-gray-300">Click to start</p>
              </div>
            ) : (
              <Link to={role.path} className="block text-center">
                <h3 className="text-2xl font-bold text-green-400 mb-2">{role.title}</h3>
                <p className="text-gray-300">Click to start</p>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingPage;