import React from 'react';

const PersonalTracker = ({ roadmap }) => {
  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-gray-800 shadow-lg rounded-lg p-8 mb-8">
        <h2 className="text-3xl font-bold text-center text-green-400 mb-8">Personal Progress Tracker</h2>
        <div className="text-center text-white">
          <p className="text-xl mb-4">Coming Soon!</p>
          <p>We're working hard to bring you a personalized progress tracking experience.</p>
          <p>Stay tuned for updates!</p>
        </div>
      </div>
    </div>
  );
};

export default PersonalTracker;