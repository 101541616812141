import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const LoadingAnimation = () => {
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotate((prev) => (prev + 120) % 360);
    }, 600);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex justify-center items-center h-64">
      <svg className="w-16 h-16" viewBox="0 0 50 50">
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke="#4ade80"
          strokeWidth="5"
          strokeLinecap="round"
          strokeDasharray="94.2477796076938 31.41592653589793"
          style={{ transform: `rotate(${rotate}deg)`, transformOrigin: 'center' }}
        />
      </svg>
    </div>
  );
};

const RoadmapItem = ({ item, isVisible, isLeft }) => {
  const props = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0px)' : 'translateY(20px)',
    config: { mass: 1, tension: 120, friction: 14 },
  });

  return (
    <animated.div style={props} className={`mb-4 ${isLeft ? 'mr-4' : 'ml-4'}`}>
      <div className={`bg-gray-700 rounded-lg shadow-lg p-4 ${item.type === 'certification' ? 'border-2 border-green-400' : ''}`}>
        <h3 className="font-bold text-green-400 text-xl mb-2">
          {item.title || item.name}
          {item.type === 'certification' && <span className="ml-2 text-sm bg-green-500 text-gray-900 px-2 py-1 rounded">Certification</span>}
        </h3>
        <p className="text-gray-300 text-sm">{item.description}</p>
        <p className="text-sm text-gray-400 mt-2">Duration: {item.duration} week</p>
        <p className="text-sm text-green-400 mt-1">Months: {item.startMonth} - {item.endMonth}</p>
        {item.cost && <p className="text-sm text-gray-300 mt-1">Cost: £{item.cost}</p>}
        {item.coveredInBootcamp && (
          <p className="text-sm text-yellow-400 mt-2">
            <a href="https://stan.store/cybercharlie" target="_blank" rel="noopener noreferrer" className="hover:underline">
              Covered on Cyber Agoge Bootcamp
            </a>
          </p>
        )}
      </div>
    </animated.div>
  );
};

const SecurityAnalystRoadmapDisplay = ({ roadmap }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setCurrentStep(0);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [roadmap]);

  useEffect(() => {
    if (!isLoading && currentStep < roadmap.items.length) {
      const timer = setTimeout(() => {
        setCurrentStep(prev => prev + 1);
      }, 250);
      return () => clearTimeout(timer);
    }
}, [currentStep, roadmap, isLoading]);

const contentProps = useSpring({
  opacity: isLoading ? 0 : 1,
  config: { duration: 500 },
});

if (!roadmap) return null;

const monthlyItems = Array.from({ length: roadmap.totalMonths }, (_, i) => i + 1).map(month => ({
  month,
  items: roadmap.items.filter(item => item.startMonth <= month && item.endMonth >= month)
}));

return (
  <div className="bg-gray-800 shadow-lg rounded-lg p-8 mb-8">
    <h2 className="text-3xl font-bold text-center text-green-400 mb-8">
      Your {roadmap.totalMonths}-Month Security Analyst Career Roadmap
    </h2>
    {isLoading ? (
      <LoadingAnimation />
    ) : (
      <animated.div style={contentProps}>
        <div className="relative">
          <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-green-400 transform -translate-x-1/2"></div>
          {monthlyItems.map(({ month, items }, monthIndex) => (
            <div key={month} className="relative mb-16">
              <div className="absolute left-1/2 w-6 h-6 bg-green-500 rounded-full border-4 border-gray-800 transform -translate-x-1/2" style={{ top: "-3px" }}></div>
              <h3 className="text-2xl font-bold text-green-400 mb-4 text-center relative z-10 bg-gray-800 inline-block left-1/2 transform -translate-x-1/2 px-4">Month {month}</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
                {items.map((item, index) => (
                  <div key={`${month}-${index}`} className={index % 2 === 0 ? "md:col-start-1" : "md:col-start-2"}>
                    <RoadmapItem
                      item={item}
                      isVisible={currentStep > roadmap.items.indexOf(item)}
                      isLeft={index % 2 === 0}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        
        <div className="mt-8 text-center">
          <a 
            href="https://stan.store/cybercharlie" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-block bg-green-500 text-white px-6 py-3 rounded-lg font-bold hover:bg-green-600 transition-colors duration-300"
          >
            Start Your Journey Here
          </a>
        </div>

        <div className="mt-8 bg-green-600 rounded-lg p-6 text-white">
          <h3 className="text-2xl font-bold mb-4">Track Your Progress</h3>
          <p className="mb-4">Personal progress tracking coming soon!</p>
          <button
            className="inline-block bg-gray-400 text-gray-800 px-6 py-3 rounded-lg font-bold cursor-not-allowed"
            disabled
          >
            Personal Tracker (Coming Soon)
          </button>
        </div>
      </animated.div>
    )}
  </div>
);
};

export default SecurityAnalystRoadmapDisplay;