import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getFeatureFlag } from './featureFlags';
import { signOut } from 'aws-amplify/auth';
import tekyuLogo from './assets/tekyu-logo.png'; // Adjust this path if needed

function Sidebar({ setIsAuthenticated }) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Sidebar rendered');
    console.log('Sidebar props:', { setIsAuthenticated });
    console.log('localStorage:', { ...localStorage });
    console.log('sessionStorage:', { ...sessionStorage });
  }, [setIsAuthenticated]);

  const handleLogout = async () => {
    try {
      await signOut({ global: true });
      localStorage.removeItem('user');
      sessionStorage.clear();
      setIsAuthenticated(false);
      navigate('/auth');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="bg-gray-800 text-white w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">
      <div className="flex items-center justify-center mb-8">
        <img src={tekyuLogo} alt="Tekyu Logo" className="h-12 w-auto" />
      </div>
      <nav>
        <Link to="/" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-green-400 hover:text-gray-900">
          Home
        </Link>
        {getFeatureFlag('showProgressTracker') && (
          <Link to="/progress" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-green-400 hover:text-gray-900">
            Progress Tracker
          </Link>
        )}
        <span className="block py-2.5 px-4 rounded transition duration-200 text-gray-500 cursor-not-allowed">
          Personal Tracker (Coming Soon)
        </span>
        <button
          onClick={handleLogout}
          className="block py-2.5 px-4 rounded transition duration-200 hover:bg-green-400 hover:text-gray-900 w-full text-left"
          >
          </button>
          </nav>
          </div>
          );
          }
          
          export default Sidebar;