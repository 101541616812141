import React, { useState, useEffect } from 'react';
import { getFeatureFlag } from './featureFlags';

function ProgressTracker({ roadmap, progress, updateProgress = () => {}, isSubscribed, setIsSubscribed }) {
  const [localProgress, setLocalProgress] = useState(progress);

  useEffect(() => {
    if (roadmap && (!localProgress || Object.keys(localProgress).length === 0)) {
      const initialProgress = {
        items: roadmap.items.reduce((acc, item) => {
          acc[item.title] = 0;
          return acc;
        }, {}),
        certifications: roadmap.certifications ? roadmap.certifications.reduce((acc, cert) => {
          acc[cert.name] = false;
          return acc;
        }, {}) : {}
      };
      setLocalProgress(initialProgress);
      updateProgress(initialProgress);
    }
  }, [roadmap, localProgress, updateProgress]);

  const handleSubscribe = () => {
    // Here you would typically integrate with a payment processor
    console.log("Subscribing..."); // Add this line for debugging
    setIsSubscribed(true);
  };

  if (!getFeatureFlag('showProgressTracker')) {
    return null;
  }

  if (!isSubscribed) {
    return (
      <div className="mt-8 bg-gray-700 p-6 rounded-lg border border-green-400">
        <h2 className="text-2xl font-bold text-center text-green-400 mb-4">Unlock Progress Tracker</h2>
        <p className="text-gray-300 mb-4">Track your progress, add comments, and stay motivated for just £6.99/month.</p>
        <button 
          onClick={handleSubscribe}
          className="w-full bg-green-500 text-gray-900 px-4 py-2 rounded-md hover:bg-green-400 transition-colors duration-300"
        >
          Subscribe Now
        </button>
      </div>
    );
  }

  if (!roadmap) return null;

  const handleCertificationToggle = (certName) => {
    const newProgress = {
      ...localProgress,
      certifications: {
        ...localProgress.certifications,
        [certName]: !localProgress.certifications[certName]
      }
    };
    setLocalProgress(newProgress);
    updateProgress(newProgress);
  };

  const handleProgressChange = (itemTitle, newProgress) => {
    const newLocalProgress = {
      ...localProgress,
      items: {
        ...localProgress.items,
        [itemTitle]: newProgress
      }
    };
    setLocalProgress(newLocalProgress);
    updateProgress(newLocalProgress);
  };

  const calculateOverallProgress = () => {
    if (!roadmap.items.length) return 0;
    const totalProgress = Object.values(localProgress.items).reduce((sum, value) => sum + value, 0);
    return (totalProgress / (roadmap.items.length * 100)) * 100;
  };

  return (
    <div className="mt-8 bg-gray-800 p-6 rounded-lg border border-green-400">
      <h2 className="text-2xl font-bold text-green-400 mb-4">Your Progress</h2>
      
      <div className="mb-6">
        <h3 className="text-xl font-semibold text-green-300 mb-2">Overall Progress</h3>
        <div className="bg-gray-700 h-4 rounded-full">
          <div 
            className="bg-green-500 h-4 rounded-full" 
            style={{width: `${calculateOverallProgress()}%`}}
          ></div>
        </div>
        <p className="text-right text-sm text-gray-300 mt-1">{calculateOverallProgress().toFixed(0)}%</p>
      </div>

      <div className="space-y-4">
        {roadmap.items.map((item, index) => (
          <div key={index}>
            <h4 className="text-lg font-medium text-green-300 mb-2">{item.title}</h4>
            <div className="flex items-center">
              <div className="bg-gray-700 h-4 rounded-full flex-grow mr-4">
                <div 
                  className="bg-green-500 h-4 rounded-full" 
                  style={{width: `${localProgress.items[item.title] || 0}%`}}
                ></div>
              </div>
              <input 
                type="number" 
                min="0" 
                max="100" 
                value={localProgress.items[item.title] || 0} 
                onChange={(e) => handleProgressChange(item.title, Math.min(100, Math.max(0, parseInt(e.target.value) || 0)))}
                className="w-16 bg-gray-700 text-white rounded px-2 py-1"
              />
              <span className="ml-1 text-gray-300">%</span>
            </div>
          </div>
        ))}
      </div>

      {roadmap.certifications && roadmap.certifications.length > 0 && (
        <div className="mt-8">
          <h3 className="text-xl font-semibold text-green-300 mb-4">Certifications</h3>
          {roadmap.certifications.map((cert, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={`cert-${index}`}
                checked={localProgress.certifications[cert.name] || false}
                onChange={() => handleCertificationToggle(cert.name)}
                className="mr-2"
              />
              <label htmlFor={`cert-${index}`} className="text-gray-300">{cert.name}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ProgressTracker;