import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SecurityAnalystRoadmapDisplay from './SecurityAnalystRoadmapDisplay';
import ProgressTracker from './ProgressTracker';
import { getFeatureFlag } from './featureFlags';
import { ChevronDown } from 'lucide-react';

const validationSchema = Yup.object().shape({
  hoursPerWeek: Yup.number().required('Required').positive('Must be positive').integer('Must be an integer'),
  experienceLevel: Yup.string().required('Required'),
  codingLevel: Yup.string().required('Required'),
  budget: Yup.string().required('Required'),
  learningStyle: Yup.string().required('Required'),
  salaryGoal6Months: Yup.number().required('Required').positive('Must be positive').integer('Must be an integer'),
  salaryGoal18Months: Yup.number().required('Required').positive('Must be positive').integer('Must be an integer'),
  workingFullTime: Yup.string().required('Required'),
});

function SecurityAnalystQuestionnaire() {
  const [roadmap, setRoadmap] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);

  const initialValues = {
    hoursPerWeek: '',
    experienceLevel: '',
    codingLevel: '',
    budget: '',
    learningStyle: '',
    salaryGoal6Months: '',
    salaryGoal18Months: '',
    workingFullTime: '',
  };

  const certificationDetails = {
    "CompTIA Network+": { baseDuration: 6, cost: 218 },
    "CompTIA Security+": { baseDuration: 7, cost: 240 },
    "CompTIA Cybersecurity Analyst (CySA+)": { baseDuration: 6, cost: 240 },
    "Microsoft Certified: Security Operations Analyst Associate": { baseDuration: 6, cost: 125 },
    "Splunk Certified Cybersecurity Defense Analyst": { baseDuration: 4, cost: 110 },
  };

  const generateRoadmap = (values) => {
    let roadmapItems = [];
    let certifications = [];
    let timelineMessage = '';
    let messageColor = '';
    const {
      hoursPerWeek, budget, experienceLevel, learningStyle, codingLevel,
      salaryGoal6Months, salaryGoal18Months, workingFullTime
    } = values;

    // Adjust timeline based on hours per week
    let timeMultiplier = 1;
    let totalMonths = 6;
    let weekAdjustment = 0;

    if (hoursPerWeek >= 0 && hoursPerWeek <= 10) {
      timeMultiplier = 1.5;
      totalMonths = 7;
      weekAdjustment = 1;
      timelineMessage = "Your timeline will be delayed.";
      messageColor = "text-red-500";
    } else if (hoursPerWeek >= 11 && hoursPerWeek <= 20) {
      timeMultiplier = 1;
    } else if (hoursPerWeek >= 21) {
      timeMultiplier = 0.75;
      weekAdjustment = -1;
      timelineMessage = "You will be on course to meet the 6-month objective.";
      messageColor = "text-green-500";
    }

    const addRoadmapItem = (title, durationWeeks, description, startMonth, endMonth, coveredInBootcamp = true) => {
      const adjustedDuration = Math.max(1, Math.ceil(durationWeeks * timeMultiplier) + weekAdjustment);
      roadmapItems.push({
        title: title.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        duration: adjustedDuration,
        startMonth,
        endMonth,
        description: description.charAt(0).toUpperCase() + description.slice(1),
        type: 'skill',
        coveredInBootcamp: coveredInBootcamp
      });
    };
    
    const addCertification = (name, durationWeeks, cost, startMonth, endMonth, coveredInBootcamp = false) => {
      const adjustedDuration = Math.max(1, Math.ceil(durationWeeks * timeMultiplier) + weekAdjustment);
      certifications.push({
        name: name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        duration: adjustedDuration,
        startMonth,
        endMonth,
        cost,
        type: 'certification',
        coveredInBootcamp: coveredInBootcamp
      });
    };

    // Core skills and certifications for Security Analyst
    addRoadmapItem("Security Fundamentals", 2, "Learn basic security concepts and principles", 1, 1, true);
    addRoadmapItem("Command Line and Terminal", 1, "Master command line interfaces", 1, 1, true);
    addRoadmapItem("Networking Fundamentals", 2, "Learn basic networking concepts and protocols", 1, 1, true);
    addRoadmapItem("Triaging", 2, "Get famliar with AWS GuardDuty and Microsoft Defender For Cloud", 5, 5, true);
    addRoadmapItem("DLP", 2, "Review Data Loss Preventon Capabilties and test using AWS Macie", 3, 4, true);
    addRoadmapItem("Learn Powershell", 2, "Complete the Learn Powershell course on Code Academy", 1, 2, false);
    addCertification("CompTIA Network+", 6, 218, 1, 2);
    addCertification("CompTIA Security+", 7, 240, 2, 3, true);
    addRoadmapItem("Learn SIEM Tools", 2, "Use free trials for Splunk, ELK, Sentinel", 5, 6, false);
    addRoadmapItem("Threat Intelligence & Threat Hunting", 2, "get familiar with MITRE ATT&CK and Threat Intelligence Tools", 4, 4, true);
    addCertification("Splunk Certified Cybersecurity Defense Analyst", 7, 110, 3, 4);
    addCertification("Microsoft Certified: Security Operations Analyst Associate", 7, 125, 4, 5);
    addCertification("Cybersecurity Analyst (CySA+)", 4, 240, 4, 6);
    addRoadmapItem("Interview Prep and CV", 1, "Prepare for job interviews and update CV", 6, 6, true);

    // Budget-based certification selection
    let totalCertificationCost = 0;
    let budgetLimit = parseInt(budget);

    if (budget === "2500") {
      budgetLimit = Infinity; // No budget limit for "£2,000+"
    }

    const addCertificationIfWithinBudget = (cert) => {
      if (totalCertificationCost + cert.cost <= budgetLimit) {
        totalCertificationCost += cert.cost;
        return true;
      }
      return false;
    };

    certifications = certifications.filter(cert => addCertificationIfWithinBudget(cert));

    // Add message about certifications based on budget
    if (budgetLimit === Infinity) {
      timelineMessage += " All certifications included based on your budget.";
    } else if (totalCertificationCost < budgetLimit) {
      timelineMessage += ` Certifications within your budget of £${budgetLimit} included.`;
    } else {
      timelineMessage += ` Some certifications omitted due to budget limit of £${budgetLimit}.`;
    }

    // Adjust for full-time work
    if (workingFullTime === 'yes') {
      timelineMessage += " As you have indicated you're in a full-time job, we recommend you utilise evening training, podcasts and apps so you can learn on the move and in your spare time.";
    } else {
      timelineMessage += " As you're not in full-time work we recommend you look to complete 20-40hrs per week on up-skilling.";
    }

    setRoadmap({ 
      items: [...roadmapItems, ...certifications].sort((a, b) => a.startMonth - b.startMonth),
      timelineMessage,
      messageColor,
      totalMonths
    });
    console.log('Roadmap generated, showing scroll arrow');
    setShowScrollArrow(true);
  };

  useEffect(() => {
    if (showScrollArrow) {
      console.log('Scroll arrow shown');
      const timer = setTimeout(() => {
        console.log('Hiding scroll arrow');
        setShowScrollArrow(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showScrollArrow]);

  const scrollToRoadmap = () => {
    console.log('Attempting to scroll to roadmap');
    const roadmapElement = document.getElementById('roadmap-section');
    if (roadmapElement) {
      console.log('Roadmap element found, scrolling');
      roadmapElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log('Roadmap element not found');
    }
  };

  return (
    <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-900 text-white py-6 relative">
      <div className="container mx-auto px-6">
        <div className="text-center mb-8">
          <h1 className="text-5xl font-bold mb-2 text-green-400">Tekyu</h1>
          <h2 className="text-3xl font-bold mb-2 text-white">Security Analyst Roadmap Calculator</h2>
          <p className="text-xl text-gray-300">Elevate Your Career in Security Analysis</p>
        </div>

        <div className="max-w-4xl mx-auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              generateRoadmap(values);
              setSubmitting(false);
            }}
          >
            {({ values, isSubmitting }) => (
              <Form className="bg-gray-800 shadow-lg rounded-lg p-8 mb-8 border border-green-400">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div className="flex flex-col">
                    <label htmlFor="hoursPerWeek" className="text-sm font-semibold mb-1 text-green-400">Hours You Can Commit Per Week</label>
                    <Field
                      type="number"
                      name="hoursPerWeek"
                      className="px-4 py-2 bg-gray-700 border border-green-400 focus:ring-green-400 focus:border-green-400 w-full rounded-md focus:outline-none text-white"
                      placeholder="e.g., 14"
                    />
                    <ErrorMessage name="hoursPerWeek" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  
                  <div className="flex flex-col">
                    <label htmlFor="experienceLevel" className="text-sm font-semibold mb-1 text-green-400">Your Experience Level</label>
                    <Field
                      as="select"
                      name="experienceLevel"
                      className="px-4 py-2 bg-gray-700 border border-green-400 focus:ring-green-400 focus:border-green-400 w-full rounded-md focus:outline-none text-white"
                    >
                      <option value="">Select Experience Level</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                    </Field>
                    <ErrorMessage name="experienceLevel" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  
                  <div className="flex flex-col">
                    <label htmlFor="codingLevel" className="text-sm font-semibold mb-1 text-green-400">Coding Level</label>
                    <Field
                      as="select"
                      name="codingLevel"
                      className="px-4 py-2 bg-gray-700 border border-green-400 focus:ring-green-400 focus:border-green-400 w-full rounded-md focus:outline-none text-white"
                    >
                      <option value="">Select Coding Level</option>
                      <option value="None">None</option>
                      <option value="Basic">Basic</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                    </Field>
                    <ErrorMessage name="codingLevel" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  
                  <div className="flex flex-col">
                    <label htmlFor="budget" className="text-sm font-semibold mb-1 text-green-400">Budget For Learning/Certifications (£)</label>
                    <Field
                      as="select"
                      name="budget"
                      className="px-4 py-2 bg-gray-700 border border-green-400 focus:ring-green-400 focus:border-green-400 w-full rounded-md focus:outline-none text-white"
                    >
                      <option value="">Select Budget</option>
                      <option value="500">£500</option>
                      <option value="1000">£1,000</option>
                      <option value="1500">£1,500</option>
                      <option value="2000">£2,000</option>
                      <option value="2500">£2,000+</option>
                    </Field>
                    <ErrorMessage name="budget" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  
                  <div className="flex flex-col">
                    <label htmlFor="learningStyle" className="text-sm font-semibold mb-1 text-green-400">Preferred Learning Style</label>
                    <Field
                      as="select"
                      name="learningStyle"
                      className="px-4 py-2 bg-gray-700 border border-green-400 focus:ring-green-400 focus:border-green-400 w-full rounded-md focus:outline-none text-white"
                    >
                      <option value="">Select Learning Style</option>
                      <option value="self-paced">Self-Paced</option>
                      <option value="structured">Structured</option>
                    </Field>
                    <ErrorMessage name="learningStyle" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  
                  <div className="flex flex-col">
                    <label htmlFor="salaryGoal6Months" className="text-sm font-semibold mb-1 text-green-400">Salary Goal In 6 Months (£)</label>
                    <Field
                      type="number"
                      name="salaryGoal6Months"
                      className="px-4 py-2 bg-gray-700 border border-green-400 focus:ring-green-400 focus:border-green-400 w-full rounded-md focus:outline-none text-white"
                      placeholder="e.g., 50000"
                    />
                    <ErrorMessage name="salaryGoal6Months" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  <div className="flex flex-col">
                    <label htmlFor="salaryGoal18Months" className="text-sm font-semibold mb-1 text-green-400">Salary Goal In 18 Months (£)</label>
                    <Field
                      type="number"
                      name="salaryGoal18Months"
                      className="px-4 py-2 bg-gray-700 border border-green-400 focus:ring-green-400 focus:border-green-400 w-full rounded-md focus:outline-none text-white"
                      placeholder="e.g., 70000"
                    />
                    <ErrorMessage name="salaryGoal18Months" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  <div className="flex flex-col">
                    <label htmlFor="workingFullTime" className="text-sm font-semibold mb-1 text-green-400">Are You Currently Working Full-Time? (40hrs)</label>
                    <Field
                      as="select"
                      name="workingFullTime"
                      className="px-4 py-2 bg-gray-700 border border-green-400 focus:ring-green-400 focus:border-green-400 w-full rounded-md focus:outline-none text-white"
                    >
                      <option value="">Select Option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Field>
                    <ErrorMessage name="workingFullTime" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-6 bg-green-500 w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none hover:bg-green-400 transition-colors duration-300 ease-in-out font-bold"
                >
                  Generate Roadmap
                </button>
              </Form>
            )}
          </Formik>
          
          {roadmap && (
            <div id="roadmap-section">
              {roadmap.timelineMessage && (
                <p className={`text-center mb-4 font-bold ${roadmap.messageColor}`}>
                  {roadmap.timelineMessage}
                </p>
              )}
              <SecurityAnalystRoadmapDisplay roadmap={roadmap} />
            </div>
          )}
          
          {getFeatureFlag('showProgressTracker') && (
            <div className="bg-gray-800 shadow-lg rounded-lg p-8 border border-green-400 mt-8">
              <ProgressTracker 
                roadmap={roadmap} 
                isSubscribed={isSubscribed} 
                setIsSubscribed={setIsSubscribed}
              />
            </div>
          )}
        </div>
      </div>

      {showScrollArrow && (
        <button 
          onClick={() => {
            console.log('Scroll arrow clicked');
            scrollToRoadmap();
          }}
          className="fixed bottom-8 left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded-full shadow-lg animate-bounce z-50"
          style={{ width: '60px', height: '60px' }}
          aria-label="Scroll to roadmap"
        >
          <ChevronDown size={36} />
        </button>
      )}
    </main>
  );
}

export default SecurityAnalystQuestionnaire;