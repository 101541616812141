import React from 'react';

function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-green-400 mb-6">Terms of Service</h1>
        <div className="space-y-4">
          <p>
            Welcome to Tekyu. By using our services, you agree to comply with and be bound by the following terms and conditions.
          </p>
          {/* Add more sections as needed */}
          <h2 className="text-2xl font-semibold text-green-300 mt-8">1. Use of Service</h2>
          <p>
            You agree to use our service for lawful purposes only and in a way that does not infringe upon the rights of others.
          </p>
          <h2 className="text-2xl font-semibold text-green-300 mt-8">2. User Accounts</h2>
          <p>
            You are responsible for maintaining the confidentiality of your account and password.
          </p>
          {/* Continue with other sections */}
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;