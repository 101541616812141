// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, Progress, UserSubscriptionPlan, Roadmap, UserSubscription, RoadmapItem, Certification } = initSchema(schema);

export {
  User,
  Progress,
  UserSubscriptionPlan,
  Roadmap,
  UserSubscription,
  RoadmapItem,
  Certification
};