import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { User, Progress, UserSubscription, Roadmap } from './models';
import ProgressTracker from './ProgressTracker';
import Sidebar from './sidebar';
import AuthPage from './AuthPage';
import Questionnaire from './Questionnaire';
import DevExQuestionnaire from './DevExQuestionnaire';
import CloudPlatformEngineerQuestionnaire from './CloudPlatformEngineerQuestionnaire';
import DevOpsQuestionnaire from './DevOpsQuestionnaire';
import SREQuestionnaire from './SREQuestionnaire';
import PenetrationTestingQuestionnaire from './PenetrationTestingQuestionnaire';
import CyberGRCQuestionnaire from './CyberGRCQuestionnaire';
import ProductManagerQuestionnaire from './ProductManagerQuestionnaire';
import AIDataEngineerQuestionnaire from './AIDataEngineerQuestionnaire';
import SecurityAnalystQuestionnaire from './SecurityAnalystQuestionnaire';
import FullStackEngineerQuestionnaire from './FullStackEngineerQuestionnaire';
import PersonalTracker from './PersonalTracker';
import LandingPage from './LandingPage';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import { getFeatureFlag } from './featureFlags';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(true); // Set to true by default
    const [isAuthenticating, setIsAuthenticating] = useState(false); // Set to false
    const [formData, setFormData] = useState({
      // ... your form data state
    });
    const [roadmap, setRoadmap] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(true);  // Set to true for testing
    const [progress, setProgress] = useState({});
    const [userId, setUserId] = useState(null);

    const defaultRoadmap = {
        items: [
            { id: 'item-1', title: 'Learn DevOps Basics', duration: 4, month: 1, description: 'Introduction to DevOps principles and practices', type: 'skill' },
            { id: 'item-2', title: 'Cloud Fundamentals', duration: 4, month: 2, description: 'Learn basics of cloud computing', type: 'skill' },
            { id: 'item-3', title: 'Security Fundamentals', duration: 4, month: 3, description: 'Introduction to cybersecurity concepts', type: 'skill' },
            { id: 'cert-1', name: 'AWS Certified Cloud Practitioner', duration: 6, month: 4, cost: 100, type: 'certification' },
            { id: 'item-4', title: 'CI/CD Pipelines', duration: 4, month: 5, description: 'Learn to build and manage CI/CD pipelines', type: 'skill' },
            { id: 'item-5', title: 'Infrastructure as Code', duration: 4, month: 6, description: 'Introduction to IaC tools like Terraform', type: 'skill' },
        ]
    };
    
    const fetchProgress = useCallback(async () => {
        try {
            const user = await getCurrentUser();
            if (!user || !user.userId) {
                console.error('No user found or userId is undefined');
                return;
            }
            const savedProgress = await DataStore.query(Progress, c => c.userId.eq(user.userId));
            if (savedProgress.length > 0) {
                setProgress(JSON.parse(savedProgress[0].data));
            }
        } catch (error) {
            console.error('Error fetching progress:', error);
        }
    }, []);

    const fetchSubscriptionStatus = useCallback(async () => {
        try {
            const subscription = await DataStore.query(UserSubscription, c => c.userId.eq(userId));
            setIsSubscribed(subscription.length > 0 && subscription[0].isActive);
        } catch (error) {
            console.error('Error fetching subscription status:', error);
        }
    }, [userId]);

    useEffect(() => {
        checkAuthState();

        const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    setIsAuthenticated(true);
                    setUserId(data.userId);
                    break;
                case 'signOut':
                    setIsAuthenticated(true); // Keep authenticated even on sign out
                    setUserId(null);
                    setProgress({});
                    setIsSubscribed(false);
                    setRoadmap(null);
                    break;
                default:
                    break;
            }
        });

        return () => unsubscribe();
    }, []);
    
    useEffect(() => {
        if (userId) {
            fetchProgress();
            fetchSubscriptionStatus();
        }
    }, [userId, fetchProgress, fetchSubscriptionStatus]);
    
    async function checkAuthState() {
        try {
            const user = await getCurrentUser();
            setIsAuthenticated(true);
            setUserId(user.userId);
        } catch {
            setIsAuthenticated(true); // Keep authenticated even if there's no user
            setUserId(null);
        } finally {
            setIsAuthenticating(false);
        }
    }

    async function updateProgress(newProgress) {
        setProgress(newProgress);
        try {
            const existing = await DataStore.query(Progress, c => c.userId.eq(userId));
            if (existing.length > 0) {
                await DataStore.save(
                    Progress.copyOf(existing[0], updated => {
                        updated.data = JSON.stringify(newProgress);
                    })
                );
            } else {
                await DataStore.save(
                    new Progress({
                        userId: userId,
                        data: JSON.stringify(newProgress)
                    })
                );
            }
        } catch (error) {
            console.error('Error saving progress:', error);
        }
    }

    async function updateSubscription(newStatus) {
        setIsSubscribed(newStatus);
        try {
            const existing = await DataStore.query(UserSubscription, c => c.userId.eq(userId));
            if (existing.length > 0) {
                await DataStore.save(
                    UserSubscription.copyOf(existing[0], updated => {
                        updated.isActive = newStatus;
                    })
                );
            } else {
                await DataStore.save(
                    new UserSubscription({
                        userId: userId,
                        isActive: newStatus
                    })
                );
            }
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const generateRoadmap = (newRoadmap) => {
        setRoadmap(newRoadmap);
    };

    if (isAuthenticating) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <div className="flex min-h-screen bg-gray-900">
                <Sidebar setIsAuthenticated={setIsAuthenticated} isSubscribed={isSubscribed} />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <Routes>
                        <Route 
                            path="/dashboard" 
                            element={<LandingPage />}
                        />
                        <Route 
                            path="/devsecops" 
                            element={
                                <Questionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/devex" 
                            element={
                                <DevExQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/cloud-platform-engineer" 
                            element={
                                <CloudPlatformEngineerQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/devops-questionnaire" 
                            element={
                                <DevOpsQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/sre-questionnaire" 
                            element={
                                <SREQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/penetration-testing" 
                            element={
                                <PenetrationTestingQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/Cybergrc" 
                            element={
                                <CyberGRCQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/product-manager" 
                            element={
                                <ProductManagerQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/ai-data-engineer" 
                            element={
                                <AIDataEngineerQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/security-analyst" 
                            element={
                                <SecurityAnalystQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/full-stack-engineer" 
                            element={
                                <FullStackEngineerQuestionnaire 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    generateRoadmap={generateRoadmap}
                                    roadmap={roadmap}
                                    isSubscribed={isSubscribed}
                                    setIsSubscribed={updateSubscription}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            } 
                        />
                        <Route 
                            path="/personal-tracker" 
                            element={<PersonalTracker roadmap={roadmap || defaultRoadmap} />}
                        />
                        {getFeatureFlag('showProgressTracker') && (
                            <Route 
                                path="/progress" 
                                element={
                                    <ProgressTracker 
                                        roadmap={roadmap || defaultRoadmap}
                                        progress={progress}
                                        updateProgress={updateProgress}
                                        isSubscribed={isSubscribed}
                                        setIsSubscribed={updateSubscription}
                                    />
                                } 
                            />
                        )}
                        <Route 
                            path="/terms-of-service" 
                            element={<TermsOfService />} 
                        />
                        <Route 
                            path="/privacy-policy" 
                            element={<PrivacyPolicy />} 
                        />
                        <Route path="/" element={<Navigate to="/dashboard" replace />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;